/* Styles Of Page */
.home {
  height: calc(100vh - 4rem);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.home .home-container {
  height: calc(100vh - 10rem);
  max-height: 500px;
  width: 90%;
  background: #212121;
  position: relative;
  border-radius: 5px;
  display: flex;
}

.home .home-container:after {
  content: "";
  position: absolute;
  top: calc(-1 * 5px);
  left: calc(-1 * 5px);
  height: calc(100% + 5px * 2);
  width: calc(100% + 5px * 2);
  background: linear-gradient(
    60deg,
    #738fbe,
    #a166ab,
    #5073b8,
    #1098ad,
    #07b39b,
    #6fba82
  );
  border-radius: calc(2 * 5px);
  z-index: -1;
  -webkit-animation: animatedgradient 3s ease alternate infinite;
  animation: animatedgradient 3s ease alternate infinite;
  background-size: 300% 300%;
}
@-webkit-keyframes animatedgradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@keyframes animatedgradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@media (max-width: 701px) {
  .home .home-container {
    height: calc(100vh - 30rem);
  }
}
