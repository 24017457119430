.home .home-container .home-text {
  color: white;
  transition: all 0.3s ease-out;
  margin: 3.2rem 0 0 3.2rem;
  width: 80%;
  position: absolute;
  z-index: 4;
}

.home .home-container h1:before {
  content: "<h1>";
  font-family: "La Belle Aurore", cursive;
  color: #515152;
  font-size: 2.3rem;
  position: absolute;
  margin-top: -1.6rem;
}
.home .home-container h1:after {
  content: "<h1/>";
  font-family: "La Belle Aurore", cursive;
  color: #515152;
  font-size: 2.4rem;
  position: absolute;
  margin-top: 8rem;
  margin-left: 3.2rem;
}

@keyframes jump {
  0% {
    transform: scale3d(1, 1, 1);
  }

  30% {
    transform: scale3d(1.25, 0.75, 1);
  }
  40% {
    transform: scale3d(0.75, 1.25, 1);
  }
  50% {
    transform: scale3d(1.15, 0.85, 1);
  }
  65% {
    transform: scale3d(0.95, 1.05, 1);
  }
  75% {
    transform: scale3d(1.05, 0.95, 1);
  }
  100% {
    transform: scale3d(1, 1, 1);
  }
}

.home .home-container .home-text span {
  font-family: Helvetica, sans-serif;
  /* font-family: "Arvo", serif; */
  font-weight: 800;
  font-size: 9.6rem;
  line-height: 13.9rem;
}
.home .home-container .home-text .normal-letter:hover {
  font-family: Helvetica, sans-serif;
  /* font-family: "Arvo", serif; */
  font-weight: 600;
  font-size: 9.6rem;
  color: #05fdd8;
  animation-name: jump;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  display: inline-block;
  transform: scale3d(0, 0, 0);
}

.home .home-container .home-text .home-J {
  display: inline;
  position: relative;
  font-size: 11.2rem;
  font-family: "Poppins", sans-serif;
  letter-spacing: 0.5rem;
  color: #05fdd8;
  font-weight: 600;
}
.home-J:after {
  content: "J";
  position: absolute;
  left: 1rem;
  color: #fd2155;
}

@media (max-width: 701px) {
  .home .home-container .home-text .home-J {
    font-size: 6rem;
  }

  .home .home-container .home-text span {
    font-family: Helvetica, sans-serif;
    font-weight: 800;
    font-size: 5rem;
    line-height: 6rem;
  }

  .home .home-container h1:before {
    content: "<h1>";
    font-family: "La Belle Aurore", cursive;
    color: #515152;
    font-size: 2.3rem;
    position: absolute;
    margin-top: -2rem;
  }
  .home .home-container h1:after {
    content: "<h1/>";
    font-family: "La Belle Aurore", cursive;
    color: #515152;
    font-size: 2.4rem;
    position: absolute;
    margin-top: 5rem;
    margin-left: -25rem;
  }
}
