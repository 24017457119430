.skill {
  font-family: "La Belle Aurore", cursive;
}

.react {
  position: absolute;
  left: 12.8rem;
  top: 6.4rem;
  font-size: 6.4rem;
  color: aqua;
  opacity: 0.2;
  transform: rotate(-5deg);
}

.html {
  position: absolute;
  left: 6.4rem;
  bottom: 6.4rem;
  font-size: 6.4rem;
  color: aqua;
  opacity: 0.2;
  transform: rotate(15deg);
}
.javascript {
  position: absolute;
  left: 32rem;
  top: 12.8rem;
  font-size: 6.4rem;
  color: aqua;
  opacity: 0.2;
  transform: rotate(-5deg);
}
.css {
  position: absolute;
  left: 40rem;
  bottom: 6.4rem;
  font-size: 6.4rem;
  color: aqua;
  opacity: 0.2;
  transform: rotate(-5deg);
}
/* Responsive */
@media (max-width: 701px) {
  .skill {
    display: none;
  }
}
