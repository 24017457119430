.header {
  display: flex;
  width: 100%;
  background-color: #212121;
  height: 6.4rem;
  justify-content: space-around;
  align-items: flex-end;
}

.active {
  display: flex;
  width: 100%;
  background-color: #212121;
  height: 6.4rem;
  justify-content: space-between;
  align-items: flex-end;
  padding-left: 5%;
  padding-right: 5%;
}

.header ul {
  display: flex;
  height: 5.1rem;
}

.header .sections {
  width: 54%;
  justify-content: space-between;
  align-items: center;
}

.header .sections a {
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 2.4rem;
  list-style: none;
}

.header .quantum-logo img {
  height: 5.1rem;
}
.sections a {
  text-decoration: none;
  color: white;
}
.sections a:hover {
  /*background: linear-gradient(330deg, #e05252 0%, #99e052 25%, #52e0e0 50%, #9952e0 75%, #e05252 100%);*/
  background: linear-gradient(
    90deg,
    rgba(38, 52, 161, 1) 0%,
    rgba(115, 143, 190, 1) 100%,
    rgba(0, 212, 255, 1) 100%
  );
  /* -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; */
  line-height: 20rem;
}

/* icons ubication */
.icons {
  width: 10rem;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

/* icono de github */

.icons .github svg {
  height: 1.6rem;
  width: 1.6rem;
}

.octicon {
  fill: white;
}

/* icono de twitter */

.icons .twitter svg {
  height: 1.6rem;
  width: 1.6rem;
}

/* icono de linkedin */

.icons .linkedin path {
  height: 1.6rem;
  width: 1.6rem;
}

.icons .linkedin svg {
  height: 2.2rem;
  width: 2.2rem;
}
